import React, { FC, ReactNode } from "react";
import { MainHeader } from "./mainheader";
import { MainFooter } from "./mainfooter";
import { Container } from "react-bootstrap";

interface LayoutProps {
    children: ReactNode;
}

const MainBody: FC<LayoutProps> = ({ children }) => {

    return (
        <div>
            <MainHeader></MainHeader>
            <div> {children} </div>
            <MainFooter></MainFooter>
        </div>
    )
};

export default MainBody;