import { Component, ReactNode } from "react";

import { NavLink } from 'react-router-dom';


export class MainHeader extends Component {
    render(): ReactNode {
        return (
            <div className="navbar-area">
                <div className="navbar-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-md-left text-center">
                                <ul className="topbar-left">
                                    <li><i className="fa fa-phone-alt"></i>+44 2045534607</li>
                                    <li><i className="fas fa-envelope"></i>info@partspanorama.co.uk</li>
                                    <li><i className="fas fa-map-marker-alt"></i>20-22 Wenlock Road, London, N1 7GU - UK</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-area navbar-expand-lg">
                    <div className="container nav-container">
                        <div className="responsive-mobile-menu">
                            <button className="menu toggle-btn d-block d-lg-none" data-target="#edumint_main_menu"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="icon-left"></span>
                                <span className="icon-right"></span>
                            </button>
                        </div>
                        <div className="logo">
                            <NavLink to="/"><img src="assets/img/Parts-Panorama-Logo.jpg" alt="img"></img></NavLink>
                        </div>
                        <div className="nav-left-part">

                        </div>
                        <div className="collapse navbar-collapse" id="edumint_main_menu">
                            <ul className="navbar-nav menu-open">
                                <li><NavLink className="text-dark" to="/">Home</NavLink></li>
                                <li><NavLink className="text-dark" to="/Stores">Stores</NavLink></li>
                                <li><NavLink className="text-dark" to="/Services">Services</NavLink></li>
                                <li><NavLink className="text-dark" to="/AboutUs">About Us</NavLink></li>
                            </ul>
                        </div>
                        <div className="nav-right-part nav-right-part-desktop">
                            <NavLink className="btn btn-base" to="/ContactUs">Contact Us <i className="ri-arrow-right-line"></i></NavLink>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}