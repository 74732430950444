import React from 'react';

import './App.css';

import MainBody from './shared/mainbody';
import { Home } from './pages/home';
import { Route, Routes } from 'react-router-dom';
import { AboutUs } from './pages/aboutus';
import { ContactUs } from './pages/contactus';
import { Services } from './pages/services';
import { Stores } from './pages/stores';

function App(): JSX.Element {
  return (

      <MainBody>
         
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AboutUs"  element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Stores" element={<Stores />} />
        </Routes>
      </MainBody>
  );
}

export default App;
