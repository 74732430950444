import { Component, ReactNode } from "react";
import { Subscribefor } from "./subpages/subscribe";
import { NavLink } from "react-router-dom";

export class Services extends Component {
    render(): ReactNode {
        return (

            <div>

                <div className="breadcrumb-area bg-overlay" style={{ backgroundImage: "url('assets/img/other/services.jpg')" }}>
                    <div className="container">
                        <div className="breadcrumb-inner">
                            <div className="section-title mb-0">
                                <h2 className="page-title">Service</h2>
                                <ul className="page-list">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Service</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service-area pd-top-115 pd-bottom-120">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="section-title text-center">
                                    <h6 className="sub-title double-line"><span></span>services</h6>
                                    <h2 className="title">Providing Auto Parts and Accessories</h2>
                                    <p className="content"><b>Parts Panorama Ltd </b>is a fast-growing online parts and accessories retailer with distribution centres in London and Cheshire. Our friendly team of experts are available to help you select the correct part for your vehicle. We strive to deliver outstanding service for our customers.
                                    </p><p><b>
                                        Parts Panorama Ltd</b> have partnered with global brands, to bring you the best products at competitive pricing, delivered direct to your door. In addition, we stock our own in-house brands to provide customers with great value for money.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center g-0">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner style-two border-right border-bottom text-center">
                                    <div className="bg-img"><img src="assets/img/shop/03.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Tools</h3>
                                        <p>Hand & Power Tools</p>
                                    </div>
                                </div>
                            </div>
                           
                           
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner style-two border-bottom border-right text-center">
                                    <div className="bg-img"><img src="assets/img/shop/02.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Oils & Additives</h3>
                                        <p>Lubrication, Fuel Additives, Flush</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner style-two border-bottom text-center">
                                    <div className="bg-img"><img src="assets/img/shop/04.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Batteries</h3>
                                        <p>Ensuring a Great Start Every Time</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner style-two border-bottom border-right text-center">
                                    <div className="bg-img"><img src="assets/img/shop/01.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Body Paints & Repair</h3>
                                        <p>Body Maintenance, Repair Tools, Fillers & Paint</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner style-two border-right border-bottom text-center">
                                    <div className="bg-img"><img src="assets/img/shop/05.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>In Car Technology</h3>
                                        <p>Electronics, SATnav & In-Car Entertainment</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner style-two border-bottom text-center">
                                    <div className="bg-img"><img src="assets/img/shop/08.jpg" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Antifreeze & Coolant</h3>
                                        <p>Concentrated Screenwash & Engine Coolant</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner style-two border-right text-center">
                                    <div className="bg-img"><img src="assets/img/shop/10.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Vehicle Lighting</h3>
                                        <p>See and be Seen</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner style-two border-right text-center">
                                    <div className="bg-img"><img src="assets/img/shop/11.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>A/C Parts</h3>
                                        <p>Keep it Cool</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner style-two text-center">
                                    <div className="bg-img"><img src="assets/img/shop/12.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Music Systems</h3>
                                        <p>Breathe Easy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Subscribefor></Subscribefor>
            </div>

        );
    }
}