import { Component, ReactNode } from "react";
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from "react-router-dom";
import { Lookingfor } from "./subpages/lookingfor";
import { Subscribefor } from "./subpages/subscribe";

export class Home extends Component {
    render(): ReactNode {
        return (

            <div>
                <div className="banner-area banner-area-2" style={{ backgroundImage: "url('assets/img/banner/banner-bg-2.png')" }}>
                    <div className="banner-bg"></div>
                    <ul className="banner-social-media">
                        <li>
                            <a className="btn-base-m" href="#">
                                <i className="ri-facebook-circle-line"></i>
                            </a>
                        </li>
                        <li>
                            <a className="btn-base-m" href="#">
                                <i className="ri-twitter-line"></i>
                            </a>
                        </li>
                        <li>
                            <a className="btn-base-m" href="#">
                                <i className="ri-instagram-line"></i>
                            </a>
                        </li>
                    </ul>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <OwlCarousel className="banner-slider owl-carousel" autoplay={true} loop margin={10} smartSpeed={500} items={1}>
                                    <div className="item">
                                        <div className="row justify-content-center">
                                            <div className="align-self-center">
                                                <div className="banner-inner style-white text-center text-lg-start">
                                                    <h1 className="b-animate-2 title">Explore the latest
                                                         collection From e-Bay </h1>
                                                    <p className="content b-animate-3">Parts Panorama Ltd is a fast-growing online parts and accessories retailer with distribution Centre's in London and Cheshire. Our friendly team of experts are available to help you select the correct part for your vehicle. We strive to deliver outstanding service for our customers.</p>
                                                    <div className="btn-wrap b-animate-4">

                                                        <a className="btn btn-base me-3" href="https://www.ebay.co.uk/usr/partspanorama" target="_blank">Explore More <i className="ri-arrow-right-line"></i></a>
                                                        <NavLink className="btn btn-border-white" to="/ContactUs">Contact Us <i className="ri-arrow-right-line"></i></NavLink>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="item">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-7 align-self-center">
                                                <div className="banner-inner style-white text-center text-lg-start">
                                                    <h1 className="b-animate-2 title">Explore the latest collection From Amazon</h1>
                                                    <p className="content b-animate-3">Parts Panorama Ltd is a fast-growing online parts and accessories retailer with distribution Centre's in London and Cheshire. Our friendly team of experts are available to help you select the correct part for your vehicle. We strive to deliver outstanding service for our customers.</p>
                                                    <div className="btn-wrap b-animate-4">
                                                    <a className="btn btn-base me-3" href="https://www.amazon.co.uk/" target="_blank">Explore More <i className="ri-arrow-right-line"></i></a>
                                                        <NavLink className="btn btn-border-white" to="/ContactUs">Contact Us <i className="ri-arrow-right-line"></i></NavLink>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-11 align-self-center text-center">
                                                <a className="video-play-btn" href="https://www.youtube.com/embed/Wimkqo8gDZ0" data-effect="mfp-zoom-in"><i className="fa fa-play"></i></a>
                                            </div>
                                        </div>
                                    </div> */}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="feature-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <div className="single-feature-wrap">
                                    <div className="thumb">
                                        <img src="assets/img/intro/01.png" alt="img"></img>
                                    </div>
                                    <div className="media-body">
                                        <h4>Free Delivery</h4>
                                        <p>UK MainLand Only</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="single-feature-wrap">
                                    <div className="thumb">
                                        <img src="assets/img/intro/02.png" alt="img"></img>
                                    </div>
                                    <div className="media-body">
                                        <h4>Moneyback Guarantee</h4>
                                        <p>Confidence with Quality</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="single-feature-wrap">
                                    <div className="thumb">
                                        <img src="assets/img/intro/03.png" alt="img"></img>
                                    </div>
                                    <div className="media-body">
                                        <h4>Customer Support</h4>
                                        <p>We always listen your questions!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wcu-area bg-relative pd-top-120 pd-bottom-120">
                    <div className="container">
                        <div className="about-area-inner">
                            <div className="row">
                                <div className="col-lg-6 col-md-11 mb-lg-0 mb-5 order-lg-2">
                                    <div className="wcu-thumb-wrap">
                                        <img className="img-2 wow fadeInRight  animated" src="assets/img/other/wcu-2.png" alt="img" style={{ visibility: "visible", animation: "fadeInRight" }}></img>
                                    </div>
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <div className="section-title mb-0">
                                        <h2 className="title left-line">Why Choose Us</h2>
                                        <p className="content mb-2">A company that is established in servicing customers which has every auto part from brake systems to body parts, shock absorbers and springs, exhaust systems, interior elements, steering systems, and clutches, to air-conditioning and heating systems, repair kits and engine oils. Parts Panorama Ltd now offers a wide range of products to all customers.</p>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <ul className="single-list-wrap">
                                                    <li><i className="ri-check-double-line"></i>Car Parts & Accessories</li>
                                                    <li><i className="ri-check-double-line"></i>Car Tuning & Styling</li>
                                                    <li><i className="ri-check-double-line"></i>Caravan, Campervan Parts</li>
                                                    <li><i className="ri-check-double-line"></i>Motorhome Parts</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6">
                                                <ul className="single-list-wrap">
                                                    <li><i className="ri-check-double-line"></i>Motorcycle Parts</li>
                                                    <li><i className="ri-check-double-line"></i>Motorcycle Accessories </li>
                                                    <li><i className="ri-check-double-line"></i>Safety & Security Accessories</li>
                                                    <li><i className="ri-check-double-line"></i>Car Care, Utility & Trailer</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <NavLink className="btn btn-base me-3" to="/AboutUs">Learn About Us <i className="ri-arrow-right-line"></i></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service-area bg-overlay pd-top-115 pd-bottom-120" style={{ backgroundImage: "url(assets/img/service/service-bg.png)" }} >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="section-title style-white text-center">
                                    <h2 className="title">Provide our best services</h2>
                                    <p className="content">As we grow, we introduce new efficiencies that are a byproduct of the strategy sessions we hold with our strategic partners.  Currently Parts Panorama Ltd is a strategic partner of global brands / manufacturers worth more than £100 Billion.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center g-0">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner border-right border-bottom text-center">
                                    <div className="bg-img"><img src="assets/img/service/1_bg.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Vehicle Parts</h3>
                                        <p>Keeping you on the road</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner border-right border-bottom text-center">
                                    <div className="bg-img"><img src="assets/img/service/2_bg.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Valeting</h3>
                                        <p>Keeping Your Machine Sparkling Clean</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner border-bottom text-center">
                                    <div className="bg-img"><img src="assets/img/service/3_bg.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Touring & Leisure</h3>
                                        <p>Parts,Caravan and Motorhome Equipment</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner border-right text-center">
                                    <div className="bg-img"><img src="assets/img/service/4_bg.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Motorcycle Parts & Accessories</h3>
                                        <p>Keeping you on 2 Wheels</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner border-right text-center">
                                    <div className="bg-img"><img src="assets/img/service/5_bg.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Accessories</h3>
                                        <p>From Air fresheners to Wheel Trims</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-service-inner text-center">
                                    <div className="bg-img"><img src="assets/img/service/6_bg.png" alt="img"></img></div>
                                    <div className="details">
                                        <h3>Towing and Trailer</h3>
                                        <p>Tow Balls, Electrics & Trailer Accessories</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testimonial-area style-one" style={{ backgroundImage: "url('assets/img/testimonial/testimo.png')" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 offset-lg-5">
                                <div className="testimonial-inner">
                                    <div className="section-title">
                                        <h3 className="title">What our clients are saying?</h3>
                                        <p className="content">With two major distribution hubs in the UK, Parts Panorama Ltd have managed to capitalise, providing quality parts and accessories at competitive prices.  With so much achieved we are excited to continue being able to introduce innovations that are aimed at facilitating our end users.</p>
                                    </div>
                                    <OwlCarousel className="testimonial-slider slider-control-dots owl-carousel" autoplay={true} loop margin={10} smartSpeed={500} items={1}>
                                        <div className="item">
                                            <div className="single-testimonial-inner">
                                                <p><span className="left-quote"><i className="fas fa-quote-left"></i></span>My Bike had some problems but I could not find any parts at some local stores near my home. So I took a look here and I really find the part I need. Really appreciate so much. Now I don't have to go to work by bus any more.<span className="right-quote"><i className="fas fa-quote-right"></i></span></p>
                                                <h4>Nilesh Patel</h4>
                                                <span>Garage Owner</span>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="single-testimonial-inner">
                                                <p><span className="left-quote"><i className="fas fa-quote-left"></i></span>Quick Response, Helpful and Good prices. Great shop for car - bike parts always online discounts<span className="right-quote"><i className="fas fa-quote-right"></i></span></p>
                                                <h4>Jason Black</h4>
                                                <span>Auto Parts Dealer</span>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Lookingfor></Lookingfor>
                <Subscribefor></Subscribefor>
            </div>
        );
    }
}