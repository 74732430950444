import { Component, ReactNode } from "react";
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Lookingfor } from "./subpages/lookingfor";
import { Subscribefor } from "./subpages/subscribe";
import { NavLink } from "react-router-dom";

export class AboutUs extends Component {
    render(): ReactNode {
        return (

            <>
                <div className="breadcrumb-area bg-overlay" style={{ backgroundImage: "url('assets/img/other/aboutus.jpg')" }}>
                    <div className="container">
                        <div className="breadcrumb-inner">
                            <div className="section-title mb-0">
                                <h2 className="page-title">About Us</h2>
                                <ul className="page-list">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="partner-area pd-top-120 pd-bottom-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <OwlCarousel className='owl-theme partner-slider-2' autoplay={true} loop margin={5} smartSpeed={250} items={5}>
                                    <div className="item">
                                        <img src="assets/img/partner/1.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/2.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/3.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/4.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/5.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/6.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/7.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/8.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/9.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/10.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/11.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/12.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/13.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/14.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/partner/15.png" alt="img"></img>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-area bg-relative pd-bottom-120">
                    <div className="container">
                        <div className="about-area-inner">
                            <div className="row">
                                <div className="col-lg-6 col-md-11">
                                    <div className="about-thumb-wrap">
                                        <img className="img-1 wow fadeInLeft animated" src="assets/img/about/1.png" alt="img"></img>
                                        <img className="img-2 wow fadeInRight animated" src="assets/img/about/2.png" alt="img"></img>
                                        <img className="img-3 wow fadeInUp animated" src="assets/img/about/3.png" alt="img"></img>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 mt-lg-0 align-self-center">
                                    <div className="about-inner-wrap">
                                        <div className="section-title mb-0">
                                            <h6 className="sub-title left-line">About Us</h6>
                                            <h2 className="title left-line">Welcome to <br/>Parts Panorama</h2>
                                            <p className="content mb-2">Parts Panorama Ltd is a leading UK online retailer of automotive parts and accessories. We stock a huge range of brands with many parts available on our shelves for the next day dispatch.</p>
                                            <p>Parts Panorama Ltd is a fast-growing online parts and accessories retailer with distribution centres in London and Cheshire. Our friendly team of experts are available to help you select the correct part for your vehicle. We strive to deliver outstanding service for our customers.</p>
                                            <p>As we grow, we introduce new efficiencies that are a byproduct of the strategy sessions we hold with our strategic partners.  Currently Parts Panorama Ltd is a strategic partner of global brands / manufacturers worth more than £100 Billion.</p>
                                            <p>With two major distribution hubs in the UK, Parts Panorama Ltd have managed to capitalise, providing quality parts and accessories at competitive prices.  With so much achieved we are excited to continue being able to introduce innovations that are aimed at facilitating our end users.</p>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="about-counter-wrap">
                                                        <div className="media">
                                                            <h2><span className="counter">25</span>+</h2>
                                                            <h4>Experienced Members</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="about-counter-wrap">
                                                        <div className="media">
                                                            <h2><span className="counter">5</span>k+</h2>
                                                            <h4>Satisfied Clients</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Lookingfor></Lookingfor>

                <div className="wcu-area bg-relative pd-top-120">
                    <div className="container">
                        <div className="about-area-inner">
                            <div className="row">
                                <div className="col-lg-6 col-md-11 mb-lg-0 mb-5 order-lg-2">
                                    <div className="wcu-thumb-wrap">
                                        <img className="img-1 wow fadeInLeft animated" src="assets/img/other/wcu-1.png" alt="img"></img>
                                    </div>
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <div className="section-title mb-0">
                                        <h2 className="title left-line">Why Choose Us</h2>
                                        <p className="content mb-2">
                                            Parts Panorama Ltd have partnered with global brands, to bring you the best products at competitive pricing, delivered direct to your door.
                                            In addition, we stock our own in-house brands to provide customers with great value for money.</p>
                                        <div className="row">
                                        <div className="col-sm-6">
                                                <ul className="single-list-wrap">
                                                    <li><i className="ri-check-double-line"></i>Car Parts & Accessories</li>
                                                    <li><i className="ri-check-double-line"></i>Car Tuning & Styling</li>
                                                    <li><i className="ri-check-double-line"></i>Caravan, Campervan Parts</li>
                                                    <li><i className="ri-check-double-line"></i>Motorhome Parts</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6">
                                                <ul className="single-list-wrap">
                                                    <li><i className="ri-check-double-line"></i>Motorcycle Parts & Accessories </li>
                                                    <li><i className="ri-check-double-line"></i>Scooter Parts & Accessories </li>
                                                    <li><i className="ri-check-double-line"></i>Safety & Security Accessories</li>
                                                    <li><i className="ri-check-double-line"></i>Car Care, Utility & Trailer</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Subscribefor></Subscribefor>
            </>

        );
    }
}