import { Component, ReactNode } from "react";

export class MainFooter extends Component {
    render(): ReactNode {
        return (
            <div>
                <footer className="footer-area bg-overlay bg-overlayfooter pd-top-60" style={{ backgroundImage: "url('assets/img/other/footer-bg.png')" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="widget widget_about pr-xl-4">
                                    <div className="thumb">
                                        <img src="assets/img/logo white.png" alt="img"></img>
                                    </div>
                                    <div className="details">
                                        <p>Parts Panorama Ltd is one of UK’s fastest growing online auto parts and accessory distributors. A young dynamic company with planned technology innovations ready to gradually introduce to the sector.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="widget widget_opening">
                                    <h3 className="widget-title">Opening Hours</h3>
                                    <ul>
                                        <li><span>Week Days</span><span className="time">8:30am - 17:30pm</span></li>
                                        <li><span>Weekend:</span><span className="time">Closed</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="widget widget_contact pl-lg-3">
                                    <h3 className="widget-title">Contact info</h3>
                                    <ul className="details">
                                        <li><i className="fa fa-map-marker-alt"></i>20-22 Wenlock Road, London, N17GU - UK</li>
                                        <li><i className="fa fa-phone-alt"></i>+44 2045534607</li>
                                        <li><i className="fa fa-envelope"></i>info@partspanorama.co.uk</li>
                                    </ul>
                                    <ul className="social-media">
                                        <li>
                                            <a className="btn-base-m" href="#">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="btn-base-m" href="#">
                                                <i className="fab fa-skype"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="btn-base-m" href="#">
                                                <i className="fab fa-google-plus-g"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="btn-base-m" href="#">
                                                <i className="fab fa-vimeo-v"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="footer-bottom text-center">
                            <div className="row">
                                <div className="col-md-6 align-self-center text-lg-start text-center">
                                    <p>Copyright © {(new Date().getFullYear())}. All rights reserved.</p>
                                </div>
                                <div className="col-md-6 align-self-center text-md-end text-center">
                                    <p>Power By <a href="https://www.shahastrasolutions.com/" target="_blank">Shahastra Solutions</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up"></i></span>
                </div>
            </div>

        );
    }
}