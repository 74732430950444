import React, { Component } from "react";

export class Lookingfor extends Component {
    render(): React.ReactNode {
        return (
            <div className="intro-area bg-overlay-2 pd-top-100 pd-bottom-90" style={{ backgroundImage: "url(assets/img/testimonial/pxfuel.jpg)" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-intro-wrap">
                                <span className="bg-border"></span>
                                <div className="thumb">
                                    <img src="assets/img/ebay.png" alt="img"></img>
                                </div>
                                <div className="details">
                                    <h3>e-Bay Store</h3>
                                    <p>e-Bay Store of Parts Panorama Ltd is a fast-growing online parts and accessories retailer with distribution Centre's in London and Cheshire. Our friendly team of experts are available to help you select the correct part for your vehicle. We strive to deliver outstanding service for our customers.</p>
                                    <a className="btn btn-border-white" href="https://www.ebay.co.uk/usr/partspanorama" target="_blank">GO TO STORE<i className="ri-arrow-right-line"></i></a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <div className="single-intro-wrap">
                                <span className="bg-border"></span>
                                <div className="thumb">
                                    <img src="assets/img/amazon.png" alt="img"></img>
                                </div>
                                <div className="details">
                                    <h3>Amazon Store</h3>
                                    <p>Amazon Store of Parts Panorama Ltd is a fast-growing online parts and accessories retailer with distribution Centre's in London and Cheshire. Our friendly team of experts are available to help you select the correct part for your vehicle. We strive to deliver outstanding service for our customers.</p>
                                    <a className="btn btn-border-white" href="https://www.amazon.co.uk/">GO TO STORE <i className="ri-arrow-right-line"></i></a>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6">
                            <div className="single-intro-wrap">
                                <span className="bg-border"></span>
                                <div className="thumb">
                                    <img src="assets/img/office.png" alt="img"></img>
                                </div>
                                <div className="details">
                                    <h3>Office Store</h3>
                                    <p>Parts Panorama Ltd is one of UK’s fastest growing online auto parts and accessory distributors. A young dynamic company with planned technology innovations ready to gradually introduce to the sector.</p>
                                    <a className="btn btn-border-white" href="https://www.amazon.co.uk/">GO TO STORE <i className="ri-arrow-right-line"></i></a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}