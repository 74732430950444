import { Component, ReactNode } from "react";
import { NavLink } from "react-router-dom";

export class ContactUs extends Component {
    render(): ReactNode {
        return (

            <div>
                <div className="breadcrumb-area bg-overlay" style={{backgroundImage: "url('assets/img/other/contactus.jpg')"}}>
                    <div className="container">
                        <div className="breadcrumb-inner">
                            <div className="section-title mb-0">
                                <h2 className="page-title">Contact Us</h2>
                                <ul className="page-list">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Contact Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-area bg-gray pd-top-110">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                 <div className="section-title text-center">
                                    <h2 className="title">Needs Help? Let's Get In Touch</h2>
                                    <p className="content">Parts Panorama Ltd is one of UK’s fastest growing online auto parts and accessory distributors. A young dynamic company with planned technology innovations ready to gradually introduce to the sector.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="contact-inner mg-bottom-120">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="contact-form-inner bg-overlay-2" style={{backgroundImage: "url(assets/img/other/contact-bg.png)"}}>
                                    <div className="section-title mb-4">
                                        <h2 className="title text-white">Get In Touch</h2>
                                    </div>
                                    <div className="media single-contact-info">
                                        <div className="media-left thumb">
                                            <img src="assets/img/other/phone.png" alt="img"></img>
                                        </div>
                                        <div className="media-body details">
                                            <h3>Contact Us</h3>
                                            <p>+44 2045534607</p>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="media single-contact-info">
                                        <div className="media-left thumb">
                                            <img src="assets/img/other/location.png" alt="img"></img>
                                        </div>
                                        <div className="media-body details">
                                            <h3>Our Location</h3>
                                            <p>20-22 Wenlock Road,</p>
                                            <p>London, N1 7GU - UK</p>
                                        </div>
                                    </div>
                                   
                                    <div className="media single-contact-info">
                                        <div className="media-left thumb">
                                            <img src="assets/img/other/envelope.png" alt="img"></img>
                                        </div>
                                        <div className="media-body details">
                                            <h3>Contact Email</h3>
                                            <p>info@partspanorama.co.uk</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="contact-form-wrap">
                                    <form className="pd-top-75">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="single-input-inner">
                                                    <input type="text" placeholder="Name"></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="single-input-inner">
                                                    <input type="text" placeholder="Subject"></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="single-input-inner">
                                                    <input type="text" placeholder="Email"></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="single-input-inner">
                                                    <input type="text" placeholder="Phone"></input>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="single-input-inner">
                                                    <textarea placeholder="Your Massage"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <button className="btn btn-base">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-g-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.0527180080644!2d-0.09369490000000001!3d51.530592899999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761d5f93737a09%3A0xd06ae5228ca10281!2sGround%20Floor%2C%2020%2C%2022%20Wenlock%20Rd%2C%20London%20N1%207GU!5e0!3m2!1sen!2suk!4v1698401604883!5m2!1sen!2suk" width="600" height="450" style={{border:0}}  loading="lazy" ></iframe>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d29208.601361499546!2d90.3598076!3d23.7803374!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1589109092857!5m2!1sen!2sbd"></iframe> */}
                </div>
            </div>

        );
    }
}