import { Component, ReactNode } from "react";
import OwlCarousel from 'react-owl-carousel';
import { Lookingfor } from "./subpages/lookingfor";
import { Subscribefor } from "./subpages/subscribe";
import { NavLink } from "react-router-dom";

export class Stores extends Component {
    render(): ReactNode {
        return (

            <div>
               
                <div className="breadcrumb-area bg-overlay" style={{ backgroundImage: "url('assets/img/other/bredcrumb-bg.png')" }}>
                    <div className="container">
                        <div className="breadcrumb-inner">
                            <div className="section-title mb-0">
                                <h2 className="page-title">Stores</h2>
                                <ul className="page-list">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Store</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog-area pd-top-115">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="section-title text-center">
                                    <h6 className="sub-title double-line"><span></span> E-Store</h6>
                                    <h2 className="title">Online Store</h2>
                                    <p className="content">A company that is established in servicing customers which has every auto part from brake systems to body parts, shock absorbers and springs, exhaust systems, interior elements, steering systems, and clutches, to air-conditioning and heating systems, repair kits and engine oils. Parts Panorama Ltd now offers a wide range of products to all customers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Lookingfor></Lookingfor>
                <div className="partner-area pd-top-120 pd-bottom-120">
                    <div className="container">
                    <h2 className="title justify-content-center text-center">Our Suppliers </h2>
                        <div className="row">
                            <div className="col-lg-12">
                                <OwlCarousel className='owl-theme partner-slider-2' autoplay={true} loop margin={5} smartSpeed={250} items={5}>
                                    <div className="item">
                                        <img src="assets/img/supplier/1.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/supplier/2.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/supplier/3.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/supplier/4.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/supplier/5.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/supplier/6.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/supplier/7.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/supplier/8.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/supplier/9.png" alt="img"></img>
                                    </div>
                                    <div className="item">
                                        <img src="assets/img/supplier/10.png" alt="img"></img>
                                    </div>
                                   
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
                <Subscribefor></Subscribefor>
            </div>

        );
    }
}