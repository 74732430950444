import React, { Component } from "react";
import EmailService from "../../service/email.service";

export class Subscribefor extends Component {

    sendemailclicked(): void {
       EmailService.sendemail('test','test');
    }

    render(): React.ReactNode {

        return (
            <div className="subscribe-area bg-gray">
                <div className="container">
                    <div className="subscribe-inner">
                        <div className="row">
                            <div className="col-lg-6 align-self-center mb-4 mb-lg-0">
                                <h3>Subscribe for our latest Offers</h3>
                                <p>Sign up to receive email updates on new product announcements, new accessories, promotional sales and more.</p>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="single-subscribe-inner style-two">
                                    <input type="text" placeholder="Your email address"></input>
                                    <button className="btn btn-base" onClick={this.sendemailclicked} >Subscribe Now <i className="ri-arrow-right-line ms-2"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}